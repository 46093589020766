import React, { useReducer, useState } from 'react';
import './App.css';

////////////// CONNECTION ////////////
const axios = require('axios').default;

// const dd_nextQ_service = 'http://localhost:8080/dermaIntake/get_next_step';
const dd_nextQ_service = `${process.env.REACT_APP_API_URL}/ai/dermaIntake/get_next_step`;
console.log('API: ', process.env.REACT_APP_API_URL)

var buttonNames = ["Age", "is_gender_male"]
var buttonTypes = ["number", "text"]
var printMsg = "500+"
var dbgPrintMsg = ""
const send_request = (id, answers) => {

  const myResponseCallback = data => {
    buttonNames[1] = data.next_q["name"];
    buttonTypes[1] = data.next_q["type"];
    printMsg = data.next_q["num remaining diagnoses"]
    dbgPrintMsg = data.next_q["remaining diagnoses"].join(", ")
    // document.getElementById("genericQ").type = buttonTypes[1]
    // document.getElementById("genericQ").value = data.next_q["values"][0]
    return buttonNames;
  };

  // post
  axios.post(dd_nextQ_service, {
    uuid: id,
    json: answers
  })
    .then(function (response) {
      console.log(response);
      const data = response.data;
      return data;
    })
    .then(myResponseCallback)
    .catch(function (error) {
      console.log(error);
    })
    .then();
}


/////////////////////////////////////
const formReducer = (state, event) => {
  return {
    ...state,
    [event.name]: event.value
  }
}


{/*
    <p>What best describes your condition?</p>
    <select name="texture" onChange={handleChange}>
     <option value="">--Please choose an option--</option>
     <option value="wet">wet</option>
    </select>
 */}
/////////////////////////////////////
function App() {
  var uuid = "Angelo";

  const [formData, setFormData] = useReducer(formReducer, {});
  const handleChange = event => {
    setFormData({
      name: event.target.name,
      value: event.target.value,
    });
  }

  // Submit 
  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = event => {
    event.preventDefault();
    setSubmitting(true);

    console.log("Sending: ", uuid, formData)
    send_request(uuid, formData);

    setTimeout(() => {
      setSubmitting(false);
      setCount(count + 1)
    }, 3000)
  }

  // question counter
  const [count, setCount] = useState(0);

  // wrapper for html input objects
  const create_gui = (name, type, values) => {
    // console.log(values)
    const restore_name = (field, subfield) => {
      return ["is", field, subfield].join("_");
    }
    if (type === "radio" || type === "checkbox") {  // expecting [{name: "...", value:"..."}]
      return (
        <label>
          <p>{values[0].name}?</p>
          {values.map(k => (
            <div>
              <input key={restore_name(k.name, k.value)} type={type} value={k.value} name={k.name} onChange={handleChange} />
              <label key={k.value} >{k.value}</label>
            </div>
          ))}
        </label>

      );
    } else if (type === "text" || type === "number") {
      return (
        <label> <p>{name}</p>
        <div>
          <input key={name} type={type} name={name} onChange={handleChange} />
          </div>
        </label>
      );
    }
  }

  return (
    <div className="wrapper">
      <h2 color="blue">DermaDetect - The future of digital dermatology!</h2>
      <h3>Answer questions about your skin condition</h3>
      <button onClick={() => window.location.reload(false)}>Reset</button>
      <p>Question {count + 1}</p>

      <form onSubmit={handleSubmit}>
        <fieldset>
          <label>
            <p id="ageTitle">{buttonNames[0]}</p>
            <input id="age" type={buttonTypes[0]} name="age" onChange={handleChange} step="1" />
          </label>

          <label>
            <p>{buttonNames[1]}?</p>
            <input id="genericQ" type="radio" value="true" name={buttonNames[1]} onChange={handleChange} /> Yes
            <input type="radio" value="false" name={buttonNames[1]} onChange={handleChange} /> No
            {/* <input type="radio" value="Unsure" name="gender" /> Unsure */}
          </label>
          {/* {create_gui("Mybutton", "checkbox", [
            { "name": "quantity", "value": "widespread" },
            { "name": "quantity", "value": "multiple" },
            { "name": "quantity", "value": "single" },
          ])} */}
        </fieldset>

        <button type="submit">Next</button>

        {submitting &&
          <div>Submitting:
            <ul>
              {Object.entries(formData).map(([name, value]) => (
                <li key={name}><strong>{name}</strong>:{value.toString()}</li>
              ))}
            </ul>
            and producing the next question...
          </div>
        }
        <p>{printMsg} possible conditions</p>

      </form>

      {/* DEBUG */}
      <button type="button" className="collapsible">debug info</button>
      <p>{dbgPrintMsg}</p>

    </div>
  );
}
export default App;
